import React from 'react';

import logoTopSrc from '../../../assets/projects/tiny_mech/top_logo.png';
import img1Src from '../../../assets/projects/tiny_mech/img1.png';
import img2Src from '../../../assets/projects/tiny_mech/img2.png';
import img3Src from '../../../assets/projects/tiny_mech/img3.png';
import img4Src from '../../../assets/projects/tiny_mech/img4.png';

export function ProjectTinymech() {
  return (
    <div className="view">
    <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>TinyMech</h1>
    
     <p className="subtitle">game design</p>
     
     <p>  Isometric survival shooter game. </p>
     <p>  Responsible for look development, robot design , 3d modeling. </p>
     
    </section> 

    <section>
        
        <figure className="fill">
          <img
            src={img1Src}
            alt="3"
          />
        </figure>
    </section>

    <section>
      <figure className="mech">
        <h3>Weapon Design</h3>
        <figure className="fill">
          <img
            src={img2Src}
            alt="3"
          />
        </figure>
        <h3>Enemy States</h3>
        <figure className="fill">
          <img
            src={img3Src}
            alt="3"
          />
        </figure>
        <h3>Design Variations</h3>
        <figure className="fill">
          <img
            src={img4Src}
            alt="3"
          />
        </figure>
      </figure>
    </section>

    </div>
  );
}
