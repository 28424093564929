import React from 'react';
import { Row, Col } from 'antd';

import logoTopSrc from '../../../assets/projects/combine_game/top_logo.png';
import colorsSrc from '../../../assets/projects/combine_game/colors.png';
import componentsSrc from '../../../assets/projects/combine_game/components.png';
import logo2Src from '../../../assets/projects/combine_game/logo_2.png';
import phoneSrc from '../../../assets/projects/combine_game/phone.png';
import screensSrc from '../../../assets/projects/combine_game/screens.png';
import tutorialSrc from '../../../assets/projects/combine_game/tutorial.png';
import websiteSrc from '../../../assets/projects/combine_game/website.png';

export function ProjectCombinegame() {
  return (
    <div className="view">
    <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>Combine 3</h1>
    
     <p className="subtitle">mobile game</p>
     
     <p> Casual mobile game designed for IOS and Android with a match 3 mechanic and a random number generator. </p>
     
    </section> 

    <section>
       <figure className="combine">  
        <figure className="padded">
          <img
            src={screensSrc}
            alt="Colors"
          />
        </figure>
        </figure>
    </section>

    <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={colorsSrc}
            alt="Colors"
          />
        </figure>
    </section>  

    <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={componentsSrc}
            alt="components"
          />
        </figure>
    </section>  

    <figure className="combine"> 
    <Row>
        <Col span={6}>

          <figure className="padded">
            <img
              src={logo2Src}
              alt="logo"
              />
          </figure>

        </Col>
        <Col span={18}>
          
          <p>The aim is to combine the blocks and mange space, game ends when there are no empty slots.</p>
          
        </Col>
      </Row>
     
        <figure className="fill">
          <img
            src={phoneSrc}
            alt="phone"
          />
        </figure>
      
        <h2>Game mechanic</h2>
     
        <figure className="fill">
          <img
            src={tutorialSrc}
            alt="tutorial"
          />
        </figure>
      </figure>

      <section>
        <h2>Page examples</h2>
        <figure className="padded">
          <img
            src={websiteSrc}
            alt="website"
          />
        </figure>
      </section>

    </div>
  );
}
