import React from 'react';

import logoTopSrc from '../../../assets/projects/ryder_logistics/top_logo.png';
import colorsSrc from '../../../assets/projects/ryder_logistics/colors.png';
import componentsSrc from '../../../assets/projects/ryder_logistics/components.png';
import img1Src from '../../../assets/projects/ryder_logistics/img1.png';
import img2Src from '../../../assets/projects/ryder_logistics/img2.png';



export function ProjectRyderlogistics() {
  return (
    <div className="view">
    <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>Ryder Logistics</h1>
    
     <p className="subtitle">header illustration</p>
     
     <p> Illustration for transport company.</p>
     <p> The illustration is meant to express the flexibility of the company in providing personalized transport.</p>
     <p> https://ryder-logistics.ro/</p>
    
    </section> 
    
    <section>
        <figure className="fill">
          <img
            src={img1Src}
            alt="img"
          />
        </figure>
    </section>

    <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={colorsSrc}
            alt="Colors"
          />
        </figure>
      </section>

      <section>
        <h2>Isometric elements</h2>
        <p> The illustration components were delivered separately to allow animation in the final website design.</p>
        <figure className="padded">
          <img
            src={componentsSrc}
            alt="Colors1"
          />
        </figure>
      </section>

      <section>
        <h2>Final website design</h2>
        <figure className="fill">
          <img
            src={img2Src}
            alt="img"
          />
        </figure>
    </section>

    </div>
  );
}
