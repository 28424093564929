import React from 'react';
import { Row, Col } from 'antd';

import topLogoSrc from '../../../assets/projects/aftr/top_logo.png';
import logoIterationSrc from '../../../assets/projects/aftr/logo_iteration.png';
import approvedLogoSrc from '../../../assets/projects/aftr/approved_logo.png';
import colorsSrc from '../../../assets/projects/aftr/colors.png';
import fontsSrc from '../../../assets/projects/aftr/fonts.png';
import headingStyleSrc from '../../../assets/projects/aftr/header.png';
import textStyleSrc from '../../../assets/projects/aftr/text_style.png';
import buttonsSrc from '../../../assets/projects/aftr/buttons.png';
import iconsSrc from '../../../assets/projects/aftr/icons.png';
import componentsSrc from '../../../assets/projects/aftr/components.png';
import bg_examplesSrc from '../../../assets/projects/aftr/bg_examples.png';
import page_examplesSrc from '../../../assets/projects/aftr/page_examples.png';





export function ProjectAftr() {
  return (
    <div className="view">
      <section>
      <figure className="topcorner" >
          <img
            src={topLogoSrc}
            alt="logo_top"
          />
        </figure>

        <h1>AFTR</h1>
        <p className="subtitle">app design</p>

        <p>AFTR is an mobile event manager application for iOS</p>
        <p>It was designed with a focus on house parties and events in clubs.
          With the app user can create,manage,sell tickets or attend
          parties.</p>

        <p>I worked on the style, logo and screen design.</p>
      </section>

      <section>
        <h2>Logo variations</h2>
        <figure className="padded">
          <img
            src={logoIterationSrc}
            alt="logo iterations"
          />
        </figure>
      </section>

      <section>
        <h2>Approved logo</h2>
        <figure className="fill" >
          <img
            src={approvedLogoSrc}
            alt="approved logo"
          />
        </figure>
      </section>

      <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={colorsSrc}
            alt="Colors"
          />
        </figure>
      </section>

      <section>
        <h2>Fonts</h2>
        <figure className="padded">
          <img
            src={fontsSrc}
            alt="Fonts"
          />
        </figure>
      </section>

      <Row>
        <Col span={12}>
          <h2>Heading styles</h2>

          <figure className="padded">
            <img
              src={headingStyleSrc}
              alt="heading style"
              />
          </figure>

        </Col>
        <Col span={12}>
          <h2>Text styles</h2>
          <figure className="padded">
            <img
              src={textStyleSrc}
              alt="text styles"
            />
          </figure>
        </Col>
      </Row>

      <section>
      <figure className="color">  
        <h3>Buttons</h3>
        <figure className="padded">
          <img
            src={buttonsSrc}
            alt="Buttons"
          />
        </figure>

        <h3>Icons</h3>
        <figure className="padded">
          <img
            src={iconsSrc}
            alt="Icons"
          />
        </figure>

        <h3>Component States</h3>
        <figure className="padded">
          <img
            src={componentsSrc}
            alt="Components"
          />
        </figure>
      
      </figure>
      
      </section>


     

      <section>
        <h2>Background variations</h2>
        <figure className="padded">
          <img
            src={bg_examplesSrc}
            alt="Background variations"
          />
        </figure>
      </section>

      <section>
        <h2>Page examples</h2>
        <figure className="padded">
          <img
            src={page_examplesSrc}
            alt="Page examples"
          />
        </figure>
      </section>
    </div>
  );
}
