import React from 'react';

import './HomeGridCard.css';
import { Link } from "@reach/router";

export function HomeGridCard(props) {
  const { coverImg, title, subtitle, slug } = props;

  return (
    <Link
      to={`/projects/${slug}`}
      title={title}
      className="Home-Grid-Card"
    >
      {
        coverImg &&
        <img
          src={coverImg}
          alt={`${title} ${subtitle} cover`}
          className="Home-Grid-Card-Img"
        />
      }
      <div className="Home-Grid-Card-Overlay">
        <p className="Home-Grid-Card-Overlay-Title">{title}</p>
        <p className="Home-Grid-Card-Overlay-Subtitle">{subtitle}</p>
      </div>
    </Link>
  );
}
