import React from 'react';

import logoTopSrc from '../../../assets/projects/personal/top_logo.png';
import img1Src from '../../../assets/projects/personal/img1.png';
import img2Src from '../../../assets/projects/personal/img2.png';
import img3Src from '../../../assets/projects/personal/img3.png';
import img4Src from '../../../assets/projects/personal/img4.png';
import img5Src from '../../../assets/projects/personal/img5.png';
import img6Src from '../../../assets/projects/personal/img6.png';
import img7Src from '../../../assets/projects/personal/img7.png';
import img8Src from '../../../assets/projects/personal/img8.png';
import img9Src from '../../../assets/projects/personal/img9.png';
import tshirt1Src from '../../../assets/projects/personal/tshirt1.png';
import tshirt2Src from '../../../assets/projects/personal/tshirt2.png';



export function ProjectPersonal() {
  return (
    <div className="view">
    <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>Personal Work</h1>
    
     <p className="subtitle">illustrations</p>
     
     <p>  Vector illustrations created to be printed on urban apparel. </p>
     
     
    </section> 

    <section>
        <figure className="fill">
          <img
            src={img1Src}
            alt="Colors"
          />
        </figure>
        <figure className="fill">
          <img
            src={img2Src}
            alt="Colors"
          />
        </figure>
        <figure className="fill">
          <img
            src={tshirt1Src}
            alt="Colors"
          />
         </figure>
         <figure className="fill">
          <img
            src={img3Src}
            alt="Colors"
          />
        </figure>
        <figure className="fill">
          <img
            src={tshirt2Src}
            alt="Colors"
          />
         </figure>
    </section>

    <section>
        
        <figure className="padded">
          <img
            src={img3Src}
            alt="3"
          />
        </figure>
        <figure className="padded">
          <img
            src={img4Src}
            alt="4"
          />
        </figure>
        <figure className="padded">
          <img
            src={img5Src}
            alt="5"
          />
        </figure>
        <figure className="padded">
          <img
            src={img6Src}
            alt="6"
          />
        </figure>
        <figure className="padded">
          <img
            src={img7Src}
            alt="7"
          />
        </figure>
        <figure className="padded">
          <img
            src={img8Src}
            alt="8"
          />
        </figure>
      </section>

      <section>
        <figure className="fill">
          <img
            src={img9Src}
            alt="9"
          />
        </figure>
    </section>

    



    </div>
  );
}
