import React from 'react';

import logoTopSrc from '../../../assets/projects/bitcoin/top_logo.png';
import img1Src from '../../../assets/projects/bitcoin/img1.png';
import img2Src from '../../../assets/projects/bitcoin/img2.png';

export function ProjectBitcoin() {
  return (
    <div className="view">
    <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>BAF Bitcoin</h1>
    
     <p className="subtitle">Motion graphic</p>
     
     <p>  Presentation video for the company. </p>
     <p>  Designed and animated the 3d infographic. </p>
     
    </section> 

    <section>
        <figure className="fill">
          <img
            src={img1Src}
            alt="1"
          />
        </figure>
    </section>

    <section>
        <figure className="fill">
          <img
            src={img2Src}
            alt="2"
          />
        </figure>
    </section>


    </div>
  );
}
