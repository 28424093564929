import React from 'react';
import { Row, Col } from 'antd';

import logoTopSrc from '../../../assets/projects/159/logo_top.png';
import buttonsSrc from '../../../assets/projects/159/buttons.png';
import colorsSrc from '../../../assets/projects/159/colors.png';
import fontsSrc from '../../../assets/projects/159/fonts.png';
import headingStyleSrc from '../../../assets/projects/159/header.png';
import iconsSrc from '../../../assets/projects/159/icons.png';
import screen1Src from '../../../assets/projects/159/screen_1.png';
import screen2Src from '../../../assets/projects/159/screen_2.png';
import screen3Src from '../../../assets/projects/159/screen_3.png';
import screen4Src from '../../../assets/projects/159/screen_4.png';
import textStyleSrc from '../../../assets/projects/159/text_style.png';

export function Project159() {
  return (
    <div className="view">
      <section>
      <figure className="topcorner" >
          <img
            src={logoTopSrc}
            alt="logo_top"
          />
        </figure>

        <h1>159 Solutions</h1>
        <p className="subtitle">dashboard design</p>

        
        <p>
          Dashboard design for analytics service. Meant for internal use to visualize projects over their life span.
          I worked with Okapi Agency Romania. I was responsible for design and icongraphy
        </p>
      </section>

      <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={colorsSrc}
            alt="Colors"
          />
        </figure>
      </section>

      <section>
        <h2>Fonts</h2>
        <figure className="padded">
          <img
            src={fontsSrc}
            alt="Fonts"
          />
        </figure>
      </section>

      <Row>
        <Col span={12}>
          <h2>Heading styles</h2>

          <figure className="padded">
            <img
              src={headingStyleSrc}
              alt="heading style"
              />
          </figure>

        </Col>
        <Col span={12}>
          <h2>Text styles</h2>
          <figure className="padded">
            <img
              src={textStyleSrc}
              alt="text styles"
            />
          </figure>
        </Col>
      </Row>

      <section>
        <h2>Buttons</h2>
        <figure className="padded">
          <img
            src={buttonsSrc}
            alt="Buttons"
          />
        </figure>
      </section>

      <section>
        <h2>Icons</h2>
        <figure className="padded">
          <img
            src={iconsSrc}
            alt="Icons"
          />
        </figure>
      </section>

      <section>
        <h2>Screen examples</h2>
        <figure className="padded">
          <img
            src={screen1Src}
            alt="Icons"
          />
        </figure>
        <figure className="padded">
          <img
            src={screen2Src}
            alt="Icons"
          />
        </figure>
        <figure className="padded">
          <img
            src={screen3Src}
            alt="Icons"
          />
        </figure>
        <figure className="padded">
          <img
            src={screen4Src}
            alt="Icons"
          />
        </figure>
      </section>

    </div>
  );
}
