import React from 'react';
import { Location, Router } from '@reach/router';
import { Drawer } from 'antd';

import { AppHeader } from './AppHeader';
import { HomeGrid } from './HomeGrid';
import { About } from './views/About';

import { projects } from '../projects';

import './App.css';
import './View.css';

function App() {
  return (
    <div className="App">
      <AppHeader/>
      <div className="App-Home-Grid-Wrapper">
        <HomeGrid />
      </div>
      <Location>
        { props =>
          <Drawer
            title={null}
            placement="right"
            closable={true}
            onClose={() => props.navigate('/')}
            visible={props.location.pathname !== '/'}
            width="80%"
            getContainer={false}
            style={{ position: 'absolute' }}
          >
            <Router>
              <About path="/about"/>
              {projects.map(project =>
                <project.content
                  key={project.slug}
                  path={`projects/${project.slug}`}
                />
              )}
            </Router>
          </Drawer>
        }
      </Location>
    </div>
  );
}

export default App;
