import React from 'react';

import logoTopSrc from '../../../assets/projects/squares/top_logo.png';
import buttonsSrc from '../../../assets/projects/squares/buttons.png';
import colorsSrc from '../../../assets/projects/squares/colors.png';
import componentsSrc from '../../../assets/projects/squares/components.png';
import botLogoSrc from '../../../assets/projects/squares/bot_logo.png';
import iconsSrc from '../../../assets/projects/squares/icons.png';
import screensSrc from '../../../assets/projects/squares/screens.png';



export function ProjectSquares() {
  return (
    <div className="view">
    <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>Squares</h1>
    
     <p className="subtitle">mobile game</p>
     
     <p> Minimalistic visual development for simple puzzle game.. </p>
     
    </section> 

    <section>
       <figure className="square">  
        <figure className="padded">
          <img
            src={screensSrc}
            alt="Colors"
          />
        </figure>
        </figure>
    </section>

    <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={colorsSrc}
            alt="Colors"
          />
        </figure>
      </section>

      <section>
        <h2>Buttons</h2>
        <figure className="padded">
          <img
            src={buttonsSrc}
            alt="buttons"
          />
        </figure>
      </section>
      <section>
        <h2>Icons</h2>
        <figure className="padded">
          <img
            src={iconsSrc}
            alt="icons"
          />
        </figure>
      </section>

      <section>
        <h2>Components</h2>
        <figure className="padded">
          <img
            src={componentsSrc}
            alt="Components"
          />
        </figure>
      </section>

      <section>
     
        <figure className="padded">
          <img
            src={botLogoSrc}
            alt="botLogo"
          />
        </figure>
      </section>

    </div>
  );
}
