import React from 'react';

import logoTopSrc from '../../../assets/projects/cbank/top_logo.png';
import colorsSrc from '../../../assets/projects/cbank/colors.png';
import detailiconsSrc from '../../../assets/projects/cbank/detail_icons.png';
import iconsSmallSrc from '../../../assets/projects/cbank/icons_small.png';
import icons1Src from '../../../assets/projects/cbank/icons1.png';
import icons2Src from '../../../assets/projects/cbank/icons2.png';
import icons3Src from '../../../assets/projects/cbank/icons3.png';
import img1Src from '../../../assets/projects/cbank/img1.png';
import img2Src from '../../../assets/projects/cbank/img2.png';



export function ProjectCbank() {
  return (
    <div className="view">
    <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>C Bank</h1>
    
     <p className="subtitle">icon design and header illustration</p>
     
     <p>
     C bank is a online branch for popular bank in Romania, worked with Okapi Agency Romania on website and mobile app, 
     I was responsible for the visual design, iconography for the website and modular 3d illustrations.
     Project not live yet.
     </p>
    </section> 

    <section>
        <figure className="fill">
          <img
            src={img1Src}
            alt="img"
          />
        </figure>
    </section>

    <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={colorsSrc}
            alt="Colors1"
          />
        </figure>
      </section>

      <section>
        <h2>Detailed icons</h2>
        <figure className="padded">
          <img
            src={detailiconsSrc}
            alt="icons1"
          />
        </figure>
        
        <figure className="padded">
          <img
            src={icons1Src}
            alt="icons1"
          />
        </figure>

        <figure className="padded">
          <img
            src={icons2Src}
            alt="icons1"
          />
        </figure>

        <figure className="padded">
          <img
            src={icons3Src}
            alt="icons1"
          />
        </figure>
      </section>

      <section>
        <h2>Pixel icons</h2>
        <figure className="padded">
          <img
            src={iconsSmallSrc}
            alt="Colors1"
          />
        </figure>
      </section>

      <section>
        <figure className="fill">
          <img
            src={img2Src}
            alt="img"
          />
        </figure>
    </section>

    </div>
  );
}
