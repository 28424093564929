// Images
import coverImgAftr from './assets/projects/aftr/aftr.png';
import coverImg159 from './assets/projects/159/159.png';

import coverImgFreelotto from './assets/projects/freelotto/freelotto.png';
import coverImgIaloc from './assets/projects/ialoc/ialoc.png';
import coverImgIng from './assets/projects/ing/ing_logo.png';
import coverImgCbank from './assets/projects/cbank/cbank.png';
import coverImgMvp from './assets/projects/mvp/mvp_logo.png';
import coverImgRyderlogistics from './assets/projects/ryder_logistics/ryder_logistics.png';
import coverImgCombineGame from './assets/projects/combine_game/combine_game.png';
import coverImgSquares from './assets/projects/squares/squares.png';
import coverImgRobot from './assets/projects/robot/robot.png';
import coverImgDevicehub from './assets/projects/device_hub/device_hub.png';

import coverImgBingoshootout from './assets/projects/bingo_shootout/bingo_shootout.png';
import coverImgBitcoin from './assets/projects/bitcoin/bitcoin.png';
import coverImgCastlebuster from './assets/projects/castle_buster/castle_buster.png';
import coverImgTinymech from './assets/projects/tiny_mech/tiny_mech.png';
import coverImgYarooms from './assets/projects/yarooms/yarooms.png';
import coverImgPersonal from './assets/projects/personal/personal.png';



// Content
import { ProjectAftr } from './components/views/projects/ProjectAftr';
import { Project159 } from './components/views/projects/Project159';
import { ProjectFreelotto } from './components/views/projects/ProjectFreelotto';
import { ProjectIaloc } from './components/views/projects/ProjectIaloc';
import { ProjectIng } from './components/views/projects/ProjectIng';
import { ProjectCbank } from './components/views/projects/ProjectCbank';
import { ProjectMvp } from './components/views/projects/ProjectMvp';
import { ProjectRyderlogistics } from './components/views/projects/ProjectRyderlogistics';
import { ProjectCombinegame } from './components/views/projects/ProjectCombinegame';
import { ProjectSquares } from './components/views/projects/ProjectSquares';
import { ProjectRobot } from './components/views/projects/ProjectRobot';
import { ProjectDevicehub } from './components/views/projects/ProjectDevicehub';
import { ProjectBingoshootout } from './components/views/projects/ProjectBingoshootout';
import { ProjectBitcoin } from './components/views/projects/ProjectBitcoin';
import { ProjectCastlebuster } from './components/views/projects/ProjectCastlebuster';
import { ProjectTinymech } from './components/views/projects/ProjectTinymech';
import { ProjectYarooms } from './components/views/projects/ProjectYarooms';
import { ProjectPersonal } from './components/views/projects/ProjectPersonal';
 /*
import coverImgBingo from './assets/projects/bingo/bingo.png';
import coverImgMillionaire from './assets/projects/wwtbam/wwtbam_logo.png';
import { ProjectBingo } from './components/views/projects/ProjectBingo';
import { ProjectMillionaire } from './components/views/projects/ProjectMillionaire';
*/ 




export const projects = [
  {
    id: 'aftr',
    slug: 'aftr',
    title: 'AFTR',
    subtitle: 'app design',
    coverImg: coverImgAftr,
    content: ProjectAftr,
  },
  {
    id: '159',
    slug: '159-solutions',
    title: '159 Solutions',
    subtitle: 'icon design and dashboard design',
    coverImg: coverImg159,
    content: Project159,
  },
  {
    id: 'freelotto',
    slug: 'freelotto',
    title: 'PockerStars',
    subtitle: 'website design',
    coverImg: coverImgFreelotto,
    content: ProjectFreelotto,
  },
  {
    id: 'ialoc',
    slug: 'ialoc',
    title: 'ialoc.ro',
    subtitle: 'logo design',
    coverImg: coverImgIaloc,
    content: ProjectIaloc,
  }, 
  {
    id: 'ing',
    slug: 'ing',
    title: 'ING SDC',
    subtitle: 'icon design & header illustration',
    coverImg: coverImgIng,
    content: ProjectIng,
  },
  {
    id: 'cbank',
    slug: 'cbank',
    title: 'C Bank',
    subtitle: 'icon design & header illustration',
    coverImg: coverImgCbank,
    content: ProjectCbank,
  },
  {
    id: 'mvp',
    slug: 'mvp',
    title: 'HowToWeb MVP Academy',
    subtitle: 'promotioanal illustration',
    coverImg: coverImgMvp,
    content: ProjectMvp,
  },
  {
    id: 'ryder_logistics',
    slug: 'ryder_logistics',
    title: 'Ryder Logistics',
    subtitle: 'header illustration',
    coverImg: coverImgRyderlogistics,
    content: ProjectRyderlogistics,
  },
  {
    id: 'combine_game',
    slug: 'combine_game',
    title: 'Combine 3',
    subtitle: 'mobile game design',
    coverImg: coverImgCombineGame,
    content: ProjectCombinegame,
  },
  {
    id: 'squares',
    slug: 'squares',
    title: 'Squares',
    subtitle: 'mobile game design',
    coverImg: coverImgSquares,
    content: ProjectSquares,
  },
  {
    id: 'robot',
    slug: 'robot',
    title: 'Interactive questionaire',
    subtitle: 'illustration',
    coverImg: coverImgRobot,
    content: ProjectRobot,
  }, 
  {
    id: 'devive_hub',
    slug: 'devive_hub',
    title: 'Device HUB',
    subtitle: 'design & UX',
    coverImg: coverImgDevicehub,
    content: ProjectDevicehub,
  },
  /*
  {
    id: 'wwtbam',
    slug: 'wwtbam',
    title: 'Who Wants to be Millionaire',
    subtitle: 'game design',
    coverImg: coverImgMillionaire,
    content: ProjectMillionaire,
  },

  {
    id: 'bingo',
    slug: 'bingo',
    title: 'Bingo',
    subtitle: 'game design',
    coverImg: coverImgBingo,
    content: ProjectBingo,
  },
  */ 
  {
    id: 'bingo_shootout',
    slug: 'bingo_shootout',
    title: 'Bingo Shootout',
    subtitle: 'game design',
    coverImg: coverImgBingoshootout,
    content: ProjectBingoshootout,
  },
  {
    id: 'bitcoin',
    slug: 'bitcoin',
    title: 'BAF Bitcoin',
    subtitle: 'motion graphic',
    coverImg: coverImgBitcoin,
    content: ProjectBitcoin,
  },
  {
    id: 'castle_buster',
    slug: 'castle_buster',
    title: 'Castle Buster',
    subtitle: 'game look development',
    coverImg: coverImgCastlebuster,
    content: ProjectCastlebuster,
  },
  {
    id: 'tiny_mech',
    slug: 'tiny_mech',
    title: 'Tiny Mech',
    subtitle: 'game look development',
    coverImg: coverImgTinymech,
    content: ProjectTinymech,
  },
  {
    id: 'yarooms',
    slug: 'yarooms',
    title: 'Yarooms',
    subtitle: 'motion graphic',
    coverImg: coverImgYarooms,
    content: ProjectYarooms,
  },
  {
    id: 'personal',
    slug: 'personal',
    title: 'Personal Work',
    subtitle: 'illustration & design',
    coverImg: coverImgPersonal,
    content: ProjectPersonal,
  }, 
  
]
