import React from 'react';
import { Row, Col } from 'antd';

import logoTopSrc from '../../../assets/projects/freelotto/top_logo.png';
import buttonsSrc from '../../../assets/projects/freelotto/buttons.png';
import colorsSrc from '../../../assets/projects/freelotto/colors.png';
import componentsSrc from '../../../assets/projects/freelotto/components.png';
import fontsSrc from '../../../assets/projects/freelotto/fonts.png';
import headingStyleSrc from '../../../assets/projects/freelotto/header.png';
import img1Src from '../../../assets/projects/freelotto/img1.png';
import img2Src from '../../../assets/projects/freelotto/img2.png';
import img3Src from '../../../assets/projects/freelotto/img3.png';
import img4Src from '../../../assets/projects/freelotto/img4.png';
import lottery_logosSrc from '../../../assets/projects/freelotto/lottery_logos.png';
import patternsSrc from '../../../assets/projects/freelotto/patterns.png';
import textStyleSrc from '../../../assets/projects/freelotto/text_style.png';
import ticketsSrc from '../../../assets/projects/freelotto/tickets.png';



export function ProjectFreelotto() {
  return (
    <div className="view">
    <section>
     
      <figure className="topcorner" >
          <img
            src={logoTopSrc}
            alt="logo_top"
          />
        </figure>
     
      <h1>PokerStars</h1>
     
      <p className="subtitle">Lotto tab</p>
      
      <p>
      Tab designed for GluckGames. I was responsible for web design, Lottery visual style, and High fidelity mock-up.
      </p>
      
    </section>

    <section>
        <figure className="padded">
          <img
            src={img1Src}
            alt="Colors"
          />
        </figure>
    </section>

    <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={colorsSrc}
            alt="Colors"
          />
        </figure>
      </section>

      <section>
        <h2>Fonts</h2>
        <figure className="padded">
          <img
            src={fontsSrc}
            alt="Fonts"
          />
        </figure>
      </section>

      <Row>
        <Col span={12}>
          <h2>Heading styles</h2>

          <figure className="padded">
            <img
              src={headingStyleSrc}
              alt="heading style"
              />
          </figure>

        </Col>
        <Col span={12}>
          <h2>Text styles</h2>
          <figure className="padded">
            <img
              src={textStyleSrc}
              alt="text styles"
            />
          </figure>
        </Col>
      </Row>

      <section>
        <h2>Buttons</h2>
        <figure className="padded">
          <img
            src={buttonsSrc}
            alt="Buttons"
          />
        </figure>
      </section>

      <section>
        <h2>Lottery Logos</h2>
        <figure className="padded">
          <img
            src={lottery_logosSrc}
            alt="Buttons"
          />
        </figure>
      </section>

      <section>
        <h2>Tickets</h2>
        <figure className="padded">
          <img
            src={ticketsSrc}
            alt="comp"
          />
        </figure>
      </section>

      <section>
        <h2>Components</h2>
        <figure className="padded">
          <img
            src={componentsSrc}
            alt="comp"
          />
        </figure>
      </section>

      <section>
        <h2>Patterns</h2>
        <figure className="fill">
          <img
            src={patternsSrc}
            alt="patterns"
          />
        </figure>
      </section>

      <section>
        <h2>Page Examples</h2>
        
        <h2>Completed ticket</h2>
        <figure className="padded">
          <img
            src={img2Src}
            alt="patterns"
          />
        </figure>

        <h2>Completed ticket</h2>
        <figure className="padded">
          <img
            src={img3Src}
            alt="patterns"
          />
        </figure>

        <h2>Completed ticket</h2>
        <figure className="padded">
          <img
            src={img4Src}
            alt="patterns"
          />
        </figure>
      </section>


    </div>
  );
}
