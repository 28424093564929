import React from 'react';

import { Card } from 'antd';
import { HomeGridCard } from './HomeGridCard';

import { projects } from '../projects';

import './HomeGrid.css';

const gridStyle = {
  width: '25%',
  padding: 0,
};

export function HomeGrid() {
  return <Card className="Home-Grid">
    { projects.map(
      (item) =>
        <Card.Grid
          key={item.slug}
          style={gridStyle}
          hoverable={false}
        >
          <HomeGridCard
            {...item}
          />
        </Card.Grid>
    ) }
  </Card>;
}
