import React from 'react';

import logoTopSrc from '../../../assets/projects/castle_buster/top_logo.png';
import img1Src from '../../../assets/projects/castle_buster/img1.png';
import img2Src from '../../../assets/projects/castle_buster/img2.png';
import img3Src from '../../../assets/projects/castle_buster/img3.png';

export function ProjectCastlebuster() {
  return (
    <div className="view">
      <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>Castle Buster</h1>
    
     <p className="subtitle">look development</p>
     
     <p>  Visual style development for fictional game. </p>
     
    </section> 

    <section>
        <figure className="fill">
          <img
            src={img1Src}
            alt="1"
          />
        </figure>
        <figure className="fill">
          <img
            src={img2Src}
            alt="2"
          />
        </figure>
    </section>

    <section>
        <p>The illustration was developed in blender 2.8 </p>
        <figure className="fill">
          <img
            src={img3Src}
            alt="3"
          />
        </figure>
    </section>

    </div>
  );
}
