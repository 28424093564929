import React from 'react';

import logoTopSrc from '../../../assets/projects/robot/top_logo.png';
import img1Src from '../../../assets/projects/robot/img1.png';
import img2Src from '../../../assets/projects/robot/img2.png';
import img3Src from '../../../assets/projects/robot/img3.png';
import img4Src from '../../../assets/projects/robot/img4.png';

export function ProjectRobot() {
  return (
    <div className="view">
      <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>Interactive questionnaire</h1>
    
     <p className="subtitle">illustration</p>
     
     <p> Online questionnaire that generates a robot avatar. Each element of the robot is chosen based on how the user answers. </p>
     
    </section> 

    <section>
        <figure className="fill">
          <img
            src={img1Src}
            alt="Colors"
          />
        </figure>
        <figure className="fill">
          <img
            src={img2Src}
            alt="Colors"
          />
        </figure>
    </section>

    <section>
        <h2>Preliminary sketches</h2>
        <figure className="padded">
          <img
            src={img3Src}
            alt="Colors"
          />
        </figure>
      </section>

      <section>
        <h2>Elements</h2>
        <figure className="fill">
          <img
            src={img4Src}
            alt="Colors"
          />
        </figure>
      </section>

    </div>
  );
}
