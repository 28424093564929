import React from 'react';

import logoTopSrc from '../../../assets/projects/yarooms/top_logo.png';
import img1Src from '../../../assets/projects/yarooms/img1.png';
import img2Src from '../../../assets/projects/yarooms/img2.png';
import img3Src from '../../../assets/projects/yarooms/img3.png';
import img4Src from '../../../assets/projects/yarooms/img4.png';

export function ProjectYarooms() {
  return (
    <div className="view">
      <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>YAROOMS</h1>
    
     <p className="subtitle">Motion graphic</p>
     
     <p>  Motion Graphic that presents the room booking application. </p>
     <p>  I was responsible for storyboarding, 3d modeling, animation and rendering. </p>
     <p>  video: https://www.youtube.com/watch?v=1dwfy5e8N98 </p>
     <p>  website: https://www.yarooms.com/ </p>
     
    </section> 

    <section>
        <figure className="fill">
          <img
            src={img1Src}
            alt="3"
          />
        </figure>
        <figure className="fill">
          <img
            src={img2Src}
            alt="3"
          />
        </figure>
        <figure className="fill">
          <img
            src={img3Src}
            alt="3"
          />
        </figure>
        <figure className="fill">
          <img
            src={img4Src}
            alt="3"
          />
        </figure>
    </section>

    </div>
  );
}
