import React from 'react';


import logoTopSrc from '../../../assets/projects/ialoc/top_logo.png';
import colorsSrc from '../../../assets/projects/ialoc/colors.png';
import img1Src from '../../../assets/projects/ialoc/img1.png';
import logoVarSrc from '../../../assets/projects/ialoc/logo_var.png';
import websiteSrc from '../../../assets/projects/ialoc/website.png';;


export function ProjectIaloc() {
  return (
    <div className="view">
      <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>ialoc.ro</h1>
    
     <p className="subtitle">logo design</p>
     
     <p>
     Logo design for a online booking service for restaurants.
      </p>
     
     <p>
    https://ialoc.ro
      </p>     
     </section>

     <section>
        <figure className="fill">
          <img
            src={img1Src}
            alt="Colors"
          />
        </figure>
    </section>

    <section>
        <h2>Logo iterations</h2>
        <figure className="padded">
          <img
            src={logoVarSrc}
            alt="Colors"
          />
        </figure>
      </section>

    <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={colorsSrc}
            alt="Colors"
          />
        </figure>
      </section>

      <section>
        <h2>Website Preview</h2>
        <figure className="padded">
          <img
            src={websiteSrc}
            alt="Colors"
          />
        </figure>
      </section>

    </div>
  );
}
