import React from 'react';

import logoTopSrc from '../../../assets/projects/ing/top_logo.png';
import colors1Src from '../../../assets/projects/ing/colors1.png';
import colors2Src from '../../../assets/projects/ing/colors2.png';
import icons1Src from '../../../assets/projects/ing/icons1.png';
import icons2Src from '../../../assets/projects/ing/icons2.png';
import img1Src from '../../../assets/projects/ing/img1.png';
import websiteSrc from '../../../assets/projects/ing/website.png';

export function ProjectIng() {
  return (
    <div className="view">
    <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>ING Software Development Center</h1>
    
     <p className="subtitle">header illustration and iconography</p>
     
     <p>
     I worked with Okapi Agency Romania, I was responsible with header illustrations and iconography.
     The style for the illustration was chosen to support ING Bank brand identity.
     The illustration aims to show a future where technology is integrated with banking systems.
     </p>
    </section> 

    <section>
        
        <figure className="fill" >
          <img
            src={img1Src}
            alt="approved logo"
          />
        </figure>
      </section>

      <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={colors1Src}
            alt="Colors1"
          />
        </figure>
      </section>

      <section>
        <h2>Icons</h2>
        <figure className="padded">
          <img
            src={icons1Src}
            alt="icons1"
          />
        </figure>
      </section>

      <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={colors2Src}
            alt="Colors2"
          />
        </figure>
      </section>

      <section>
        <h2>Icons</h2>
        <figure className="padded">
          <img
            src={icons2Src}
            alt="icons2"
          />
        </figure>
      </section>

      <section>
        <h2>Preview on webpage</h2>
        <figure className="padded">
          <img
            src={websiteSrc}
            alt="website"
          />
        </figure>
      </section>

    </div>
  );
}
