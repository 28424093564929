import React from 'react';

import './About.css';

import iconsSrc from '../../assets/icons/software.png';


export function About() {
  return (
    <div className="view">
      <h1>Alexandru Goran</h1>
      <p className="skills">design / illustration / animation</p>
      
      <p>  
        Hi! I am Alex, a Romanian product designer focused on UI and Visual Design, based in Berlin.
        My work combines a background in brand design with technology and human centered design thinking. I have been working with a variety of creative teams and thinkers across finance, retail and mobile gaming. 
      </p>
      
      <sectios>
      <h2>Studies</h2>
      <p>  2008 - 2011 Product Design - University of Fine Arts, Bucharest Romania </p>
      </sectios>

      <sectios>
      <h2>Where I worked</h2>
      <p>  2016 - present - Lead graphic designer - GlückGames GMBH </p>
      <p>  2012 - 2016 - Graphic designer - Okapi Agency Romania </p>
      </sectios>

      
      <section>
      <h2>Software</h2>
        <figure className="padded">
          <img
            src={iconsSrc}
            alt="icons"
          />
        </figure>
      </section>

      <sectios>
      <h2>Where to find me</h2>
      <p>  goranalexandru@gmail.com </p>
      <p>  https://www.instagram.com/goran.alexandru/ </p>
      </sectios>
      
    
    </div>
  );
}


