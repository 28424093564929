import React from 'react';

import logoTopSrc from '../../../assets/projects/device_hub/top_logo.png';
import colorsSrc from '../../../assets/projects/device_hub/colors.png';
import componentsSrc from '../../../assets/projects/device_hub/components.png';

import imgBlackSrc from '../../../assets/projects/device_hub/img_black.png';
import imgWhiteSrc from '../../../assets/projects/device_hub/img_white.png';
import wireframeSrc from '../../../assets/projects/device_hub/wireframe.png';
import logoSrc from '../../../assets/projects/device_hub/logo.png';

export function ProjectDevicehub() {
  return (
    <div className="view">
      <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>Device HUB</h1>
    
     <p className="subtitle">web app</p>
     
     <p> 
       Device HUB is a Romanian start-up that lets non-engineers setup home appliances that are connected to the internet.
       I worked with Okapi Agency Romania. I was responsible with the UX and visual development of the UI elements. 
     </p>
     
    </section> 

    <section>
        <figure className="padded">
          <img
            src={logoSrc}
            alt="logo"
          />
        </figure>
    </section>

    <section>
        
        <figure className="padded">
          <img
            src={imgWhiteSrc}
            alt="white"
          />
        </figure>
      </section>

      <section>
        
        <figure className="padded">
          <img
            src={imgBlackSrc}
            alt="Colors"
          />
        </figure>
      </section>

      <section>
        <h2>Colors</h2>
        <figure className="padded">
          <img
            src={colorsSrc}
            alt="Colors"
          />
        </figure>
      </section>

      <section>
        <h2>Components</h2>
        <figure className="padded">
          <img
            src={componentsSrc}
            alt="Components"
          />
        </figure>
      </section>

      <section>
        <h2>Wireframe</h2>
        <figure className="padded">
          <img
            src={wireframeSrc}
            alt="Wireframe"
          />
        </figure>
      </section>


    </div>
  );
}
