import React from 'react';

import logoTopSrc from '../../../assets/projects/bingo_shootout/top_logo.png';
import img1Src from '../../../assets/projects/bingo_shootout/img1.png';
import img2Src from '../../../assets/projects/bingo_shootout/img2.png';
import img3Src from '../../../assets/projects/bingo_shootout/img3.png';
import img4Src from '../../../assets/projects/bingo_shootout/img4.png';
import img5Src from '../../../assets/projects/bingo_shootout/img5.png';

export function ProjectBingoshootout() {
  return (
    <div className="view">
      <section>
     
     <figure className="topcorner" >
         <img
           src={logoTopSrc}
           alt="logo_top"
         />
       </figure>
    
     <h1>BingoShootout</h1>
    
     <p className="subtitle">browser real money game</p>
     
     <p> Game designed for GluckGames. I worked on the UX, UI, character animation. </p>
     <p> https://www.lottoland.com/en/instantwin/bingoshootout </p>
     
    </section> 

    <section>
        <figure className="padded">
          <img
            src={img1Src}
            alt="1"
          />
        </figure>

        <figure className="padded">
          <img
            src={img2Src}
            alt="2"
          />
        </figure>

        <figure className="padded">
          <img
            src={img3Src}
            alt="3"
          />
        </figure>

        <figure className="padded">
          <img
            src={img4Src}
            alt="4"
          />
        </figure>
    </section>

    <section>
        <p>Character was modeled, animated and redered in Cinema4D.</p>
        <figure className="padded">
          <img
            src={img5Src}
            alt="5"
          />
        </figure>
    </section>



    </div>
  );
}
