import React from 'react';
import { Link } from "@reach/router";
import './AppHeader.css';
import logo from '../assets/logo.png';

export function AppHeader() {
  return (
    <header className="App-Header">
      <Link className="Header-Home-Link" to="/">
        <img alt="logo" src={logo}/>
      </Link>
      <Link className="Header-Contact-Link" to="/about">
        about
      </Link>
    </header>
  );
}
